.bodyNT {
	background: linear-gradient(-45deg, #ffffff, #f6f8f7, #f0f3f3, #f1f3f2);
	background-size: 200% 200%;
	animation: gradient 12s ease infinite;
	min-height: 100vh;
    height:100vh;
    scroll-snap-type: y mandatory;
    overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}