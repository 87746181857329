.item_image_1 {
  grid-column-start: 1;
  grid-column-end: 5;
  position: relative;
  background-color: #e9f3e1;
  display: flex;
  justify-content: center;
  /* box-shadow: 0.2rem 0.2rem 0.4rem #c8d0e7, -0.3rem -0.3rem 0.5rem #ffffff; */
}

.item_image_2 {
  grid-column-start: 1;
  grid-column-end: 3;
  position: relative;
  background-color: #e9f3e1;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  /* box-shadow: 0.2rem 0.2rem 0.4rem #c8d0e7, -0.3rem -0.3rem 0.5rem #ffffff; */
}

.item_image_3 {
  grid-column-start: 3;
  grid-column-end: 5;
  position: relative;
  background-color: #e9f3e1;
  /* align-items: center; */
  text-align: center;
  display: flex;
  justify-content: center;
  /* box-shadow: 0.2rem 0.2rem 0.4rem #c8d0e7, -0.3rem -0.3rem 0.5rem #ffffff; */

  /* align-content: center; */
}
